import Stack from "@mui/material/Stack";
import InputField from "Components/Shared/textField";
import Box from "@mui/material/Box";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";

import Progress from "Components/Shared/loading";
import DialogComponent from "Components/Shared/dailog";
import { useForm } from "Utils/hooks";
import { formData, request } from "Utils";
import { useState } from "react";
import { useMutation } from "react-query";
import { AddFunc } from "./type";
import Image from "Components/Shared/image";
import Person2Outlined from "@mui/icons-material/Person2Outlined";
import Typography from "@mui/material/Typography";

const Add: AddFunc = ({ toggleToast, isOpen, closeDialog, refetch }) => {
  const { getData, values, submit, reset } = useForm(submitForm);
  const [isLoading, setIsloading] = useState(false);
  const [previewImg, setPreviewImg] = useState("");
  const [profilePhoto, setProfilePhoto] = useState("");
  function preview(e: any) {
    const objectUrl = e.target.files && URL.createObjectURL(e.target.files[0]);
    values.header_image = e.target.files[0];
    objectUrl && setPreviewImg(objectUrl);
    return () => objectUrl && URL.revokeObjectURL(objectUrl);
  }

  function previewProfilePhoto(e: any) {
    const objectUrl = e.target.files && URL.createObjectURL(e.target.files[0]);
    values.profile_photo = e.target.files[0];
    objectUrl && setProfilePhoto(objectUrl);
    return () => objectUrl && URL.revokeObjectURL(objectUrl);
  }

  const mutation = useMutation(
    async () => {
      setIsloading(true);
      if (values.country) values.country = values.country.toLowerCase();
      values.hashtags = [{ hashtag: values.hashtags }];
      return await request.post({
        url: "/community/",
        data: formData(values)
      });
    },
    {
      onSuccess: ({ message }) => {
        reset();
        toggleToast(message);
        refetch();
        setIsloading(false);
        closeDialog();
      },
      onError: ({ message }) => {
        toggleToast(message);
        setIsloading(false);
      }
    }
  );

  function submitForm() {
    mutation.mutate();
  }

  return (
    <div>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title="Add new community"
        maxWidth="sm"
      >
        <form onSubmit={submit}>
          <Stack spacing={4} py={5}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
              <InputField
                type="text"
                label="Name"
                name="name"
                onChange={getData}
                sx={{ width: { xs: "100%", sm: "49%" } }}
                required
              />
              <InputField
                type="text"
                label="Hashtags"
                name="hashtags"
                onChange={getData}
                sx={{ width: { xs: "100%", sm: "49%" }, mt: { xs: 4, sm: 0 } }}
                required
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
              <InputField
                type="text"
                label="Country"
                name="country"
                onChange={getData}
                sx={{ width: { xs: "100%", sm: "49%" } }}
                required
              />
              <FormControl
                required
                sx={{ width: { xs: "100%", sm: "49%" }, mt: { xs: 4, sm: 0 } }}
              >
                <InputLabel id="demo-simple-select-label">Privacy</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={value.day}
                  label="Privacy"
                  // onChange={(e)=>getData(e)}
                >
                  <MenuItem value="monday">Public</MenuItem>
                  <MenuItem value="tuesday">Privacy</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <InputField
              type="text"
              label="Website"
              name="website"
              onChange={getData}
              required
            />
            <TextareaAutosize
              onChange={(e) => (values.bio = e.target.value)}
              placeholder="Typing bios"
              style={{ borderRadius: 5, padding: "20px" }}
              maxRows={4}
              required
            />
            <InputField
              type="file"
              label="Image"
              id="header_image"
              onChange={preview}
              required
              sx={{ display: "none" }}
            />
            <Box
              onClick={() => {
                document.getElementById("header_image")?.click();
              }}
              sx={{
                border: "solid 1px #dbdbdb",
                textAlign: "Center",
                borderRadius: 2,
                py: 3,
                color: "#333333",
                cursor: "pointer"
              }}
            >
              {previewImg ? (
                <Image
                  src={previewImg}
                  alt={values.name}
                  height="200px"
                  width="100%"
                />
              ) : (
                <>
                  <Person2Outlined sx={{ fontSize: 100 }} />
                  <Typography textAlign="center">
                    Upload header image
                  </Typography>
                </>
              )}
            </Box>
            <InputField
              type="file"
              label="Image"
              id="profile_photo"
              onChange={previewProfilePhoto}
              required
              sx={{ display: "none" }}
            />
            <Box
              onClick={() => {
                document.getElementById("profile_photo")?.click();
              }}
              sx={{
                border: "solid 1px #dbdbdb",
                textAlign: "Center",
                borderRadius: 2,
                py: 3,
                color: "#333333",
                cursor: "pointer"
              }}
            >
              {profilePhoto ? (
                <Image
                  src={profilePhoto}
                  alt={values.name}
                  height="200px"
                  width="200px"
                  style={{ borderRadius: "50%" }}
                />
              ) : (
                <>
                  <Person2Outlined sx={{ fontSize: 100 }} />
                  <Typography textAlign="center">
                    Upload profile photo
                  </Typography>
                </>
              )}
            </Box>
            <Button type="submit" variant="contained">
              Add community
              {isLoading && (
                <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
              )}
            </Button>
          </Stack>
        </form>
      </DialogComponent>
    </div>
  );
};

export default Add;
